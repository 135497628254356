import React from "react";
import {
  H3_ABOUT_IMG01,
  H3_ABOUT_IMG02,
  H3_ABOUT_SHAPE01,
} from "../../lib/assets";

export const AboutNine = () => {
  return (
    <section className="about-area-nine pt-70 pb-70">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-7 col-md-10 order-0 order-lg-2">
            <div className="about-img-wrap-four">
              <div className="mask-img-wrap">
                <img src={H3_ABOUT_IMG01} alt="Services" />
              </div>
             
              <img src={H3_ABOUT_IMG02} alt="HNA LAW" className="img-two" />
              <div className="about-shape-wrap-three">
                <img src={H3_ABOUT_SHAPE01} alt="About Us" />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-content-four">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style1">
                <span className="sub-title tg-element-title">Our Services</span>
                <h2 className="title tg-element-title">
                Comprehensive Representation Services for Legal Matters
                </h2>
              </div>
              <p>
              We offer tailored solutions to ensure your interests are protected every step of the way.
              </p>
              <div className="about-list-two">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="fas fa-arrow-right"></i>
                    </div>
                    <div className="content">
                      <h6 >Assistance in filing of Industry Representations before the Central & State Govt Authorities.</h6>
                    
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-arrow-right"></i>
                    </div>
                    <div className="content">
                      <h6 >Representation Services at other for and Authority for Advanced Ruling.</h6>
                     
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                    <i className="fas fa-arrow-right"></i>
                    </div>
                    <div className="content">
                      <h6 >Representation Services at High Court and Supreme Court.</h6>
                    
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-arrow-right"></i>
                    </div>
                    <div className="content">
                      <h6 >Strategy advisory w.r.t. litigation aspects.</h6>
                    
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-arrow-right"></i>
                    </div>
                    <div className="content">
                      <h6 >Tax structuring and optimization from litigation perspective.</h6>
                    
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
