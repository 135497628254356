import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { contactSchema } from "./contactSchema";
import { CONTACT_SHAPE } from "../../lib/assets";

export const ContactAreaOne = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(contactSchema),
  });
  const [notification, setNotification] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const hasErrors = errors && Object.keys(errors).length > 0; // Track whether errors are present

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/submitContact`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Check response status and handle accordingly
      if (response.status === 201) {
        setNotification({
          type: "success",
          message: "Thank you for your submission!",
        });
        setFormSubmitted(true);
      } else {
        setNotification({
          type: "error",
          message: "Failed to submit. Please try again later.",
        });
      }
    } catch (error) {
      // Handle error from Axios request
      console.error("Error submitting form:", error);
      setNotification({
        type: "error",
        message: "Failed to submit. Please try again later.",
      });
    }
  };


  return (
    <section className="contact-area contact-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="contact-content">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style2">
                <span className="sub-title">GET IN TOUCH</span>
                <h2 className="title tg-element-title">
                  Connecting You with Legal Expertise!
                </h2>
              </div>
              <p>Have questions? We're here to help.</p>
            </div>
          </div>

          <div className="col-lg-7 ">
            <div className="contact-form">
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ opacity: formSubmitted ? 0.3 : 1 }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className={`form-grp ${errors.name ? "error" : ""}`}>
                      <input
                        placeholder="Name *"
                        {...register("name")}
                        className={`input-field ${errors.name ? "error" : ""}`}
                        disabled={
                          formSubmitted && notification?.type === "success"
                        }
                      />
                      {errors.name && (
                        <span className={`error-message error-message-name`}>
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={`form-grp ${errors.email ? "error" : ""}`}>
                      <input
                        type="email"
                        placeholder="Email *"
                        {...register("email")}
                        className={`input-field ${errors.email ? "error" : ""}`}
                        disabled={
                          formSubmitted && notification?.type === "success"
                        }
                      />
                      {errors.email && (
                        <span className={`error-message error-message-email`}>
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={`form-grp ${errors.phone ? "error" : ""}`}>
                      <input
                        type="tel"
                        placeholder="Phone *"
                        {...register("phone", { maxLength: 15 })}
                        className={`input-field ${errors.phone ? "error" : ""}`}
                        disabled={
                          formSubmitted && notification?.type === "success"
                        }
                        maxLength="15"
                      />
                      {errors.phone && (
                        <span className={`error-message error-message-phone`}>
                          {errors.phone.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={`form-grp ${errors.state ? "error" : ""}`}>
                      <input
                        placeholder="State *"
                        {...register("state")}
                        className={`input-field ${errors.state ? "error" : ""}`}
                        disabled={
                          formSubmitted && notification?.type === "success"
                        }
                      />
                      {errors.state && (
                        <span className={`error-message error-message-state`}>
                          {errors.state.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className={`form-grp ${
                        errors.organisation ? "error" : ""
                      }`}
                    >
                      <input
                        placeholder="Organisation *"
                        {...register("organisation")}
                        className={`input-field ${
                          errors.organisation ? "error" : ""
                        }`}
                        disabled={
                          formSubmitted && notification?.type === "success"
                        }
                      />
                      {errors.organisation && (
                        <span
                          className={`error-message error-message-organisation`}
                        >
                          {errors.organisation.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className={`form-grp ${errors.message ? "error" : ""}`}
                    >
                      <textarea
                        placeholder="Message *"
                        {...register("message")}
                        className={`input-field ${
                          errors.message ? "error" : ""
                        }`}
                        disabled={
                          formSubmitted && notification?.type === "success"
                        }
                      ></textarea>
                      {errors.message && (
                        <span className={`error-message error-message-message`}>
                          {errors.message.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  id="sendButton"
                  type="submit"
                  disabled={isSubmitting || formSubmitted}
                  style={{ opacity: hasErrors ? 0.5 : 1 }}
                >
                  {!isSubmitting ? (
                    "Send Now"
                  ) : (
                    <span className="indicator-progress">
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </form>
              {notification && (
                <div className={`thank-you-message ${notification.type}`}>
                  {notification.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="contact-shape">
        <img src={CONTACT_SHAPE} alt="" />
      </div>
    </section>
  );
};

export default ContactAreaOne;
