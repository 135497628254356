import React, { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { LOGO } from "../../lib/assets";

export const MobileMenu = () => {
  useEffect(() => {
  //SubMenu Dropdown Toggle
  if ($(".menu-area li.menu-item-has-children ul").length) {
    $(".menu-area .navigation li.menu-item-has-children").append(
      '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
    );

    // Handle submenu click
    $(".menu-area li.menu-item-has-children .dropdown-btn").on("click", function () {
      $(this).toggleClass("open");
      $(this).siblings("ul").slideToggle(300); // Toggle sibling ul
    });
  }

  //Mobile Nav Hide Show
  if ($(".mobile-menu").length) {
    var mobileMenuContent = $(".menu-area .main-menu").html();
    $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

    //Dropdown Button
    $(".mobile-menu li.menu-item-has-children .dropdown-btn").on("click", function () {
      $(this).toggleClass("open");
      $(this).siblings("ul").slideToggle(300); // Toggle sibling ul
    });

    //Menu Toggle Btn
    $(".mobile-nav-toggler").on("click", function () {
      $("body").addClass("mobile-menu-visible");
    });

    //Menu Toggle Btn
    $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
      $("body").removeClass("mobile-menu-visible");
    });
  }
}, []);


  return (
    <>
      <div className="mobile-menu">
        <nav className="menu-box">
          <div className="close-btn">
            <i className="fas fa-times"></i>
          </div>
          <div className="nav-logo">
            <Link to="/">
              <img src={LOGO} alt="Logo" />
            </Link>
          </div>
          
          <div className="menu-outer">
            {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
            
            <ul className="p-0 m-0">
                      <li className="mobile-li ">
                        <a href ="/contact" className="mobile-nav">
                          Contact Us
                        </a>
                      </li>
                     </ul>
          </div>
         
       
        </nav>
      </div>
      <div className="menu-backdrop"></div>
      
    </>
  );
};
