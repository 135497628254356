import React from "react";

export const ContactAreaMap = () => {
  return (
    <div className="contact-map">
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.7741877090616!2d77.58847068527788!3d12.922229983457502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15a777d5311b%3A0x19a8a7ba86912901!2sH%20N%20A%20%26%20Co.%20LLP%20(formerly%20known%20as%20Hiregange%20%26%20Associates%20LLP)!5e0!3m2!1sen!2sin!4v1706011163039!5m2!1sen!2sin"
        width="100%"
        height="300"
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};
