import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import { tg_title_animation } from "./lib/gsap/tg_title_animation";

import { useParticles } from "./lib/hooks/useParticles";
import { useParallax } from "./lib/hooks/useParallax";
import ApplyForm from "./pages/ApplyForm";

import { useHasAnimation } from "./lib/hooks/useHasAnimation";

import AboutUs from "./pages/About/AboutUs";
import TermsNConditions from "./pages/TermsNConditions";
import ServicesPage from "./pages/Services/ServicesPage";
import PartnerDetailsPage from "./pages/PartnerDetails/PartnerDetailsPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import CareersPage from "./pages/CareerPages/CareersPage";
import ContactPage from "./pages/Contact/ContactPage";

import { useAos } from "./lib/hooks/useAos";
import { useJarallax } from "./lib/hooks/useJarallax";

function App() {
  useAos();
  useJarallax();
  useParallax();
  useParticles();
  useHasAnimation();

  // title, subtitle animation
  useEffect(() => {
    tg_title_animation();
  }, []);

  // on route change to top of the page
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-n-conditions" element={<TermsNConditions />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/partner-details" element={<PartnerDetailsPage />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/applyform" element={<ApplyForm />} />
      </Routes>
    </>
  );
}

export default App;
