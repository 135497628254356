import React  from "react";
import { Link } from "react-router-dom";

export const TeamAreaFourItem = ({ member }) => {

  return (
    <div className="team-item-four">
      <div className="team-thumb-four">
        <img src={member.src} alt="" />

      
      </div>

      <div className="team-content-four">
        <h2 className="title">
          <Link to={member.href}>{member.title}</Link>
        </h2>
        <span>{member.designation} </span>
      </div>
    </div>
  );
};
