import React, { useState } from "react";

export const ServicesThreeItem = ({ service }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleApplyClick = () => {
    // Construct the URL with _id parameter
    const applyFormUrl = `/applyform?id=${service._id}`;
    // Open a new window with the applyFormUrl
    window.open(applyFormUrl, "_blank");
  };

  return (
    <div className="border rounded-md mb-4 p-2 bg-white">
      <div
        className="flex d-flex justify-content-between items-center px-4 py-2 cursor-pointer"
        onClick={toggleAccordion}
      >
        <h5 className="text-base md:text-lg lg:text-xl font-semibold mr-2 text-break">
          {service.position}
        </h5>
        <i className={" justify-content-end " + (isOpen ? "flaticon-arrow" : "flaticon-down-arrow")}></i>
      </div>
      {isOpen && (
        <div className="px-4 py-2 text-break">
          <div className="mb-2"><strong>Roles and Responsibility:</strong></div>
          <ul className="list-disc ml-8 mb-2">
            {service.content.split('\n').map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p className="mt-2 text-break">
            <strong>Required Qualification:</strong> {service.qualification}
          </p>
          <p className="mt-2">
            <strong>Vacancies:</strong> {service.city}
          </p>
          <button onClick={handleApplyClick} className="btn transparent-btn-two mt-3 hover:bg-blue-600 view-all-btn text-end mb-30 transition duration-200">
            Apply Now
          </button>
        </div>
      )}
    </div>
  );
};
