import React from "react";
import { Layout } from "./../layouts/Layout";
import { Helmet } from "react-helmet";

const TermsNConditions = () => {
  return (
    <Layout header={6} footer={1} breadcrumb={"T&C"}  title={'Terms & Conditions'}>
       <Helmet>
        <title>Terms and Conditions | HNA Law Chambers</title>
        <meta
          name="description"
          content="Explore the terms and conditions governing our legal services at H N A Law Chambers. From representation services to tax structuring, understand the guidelines for our tailored legal solutions."
        />
      </Helmet>
      <div className="container py-5">
      <div className="row">
        <div className="col-lg-12">
          <h2 className="mt-5 mb-4">Disclaimer</h2>
          <p>The content of this site is for general information and guidance only. By accessing any content on this website, the user acknowledges that:</p>
          <ul>
            <li>The information provided does not amount to advertising or solicitation.</li>
            <li>The information is meant only for his/her information.</li>
            <li>All content provided on this website has been checked for accuracy.</li>
          </ul>
          <p><strong>H N A & Co. LLP shall not be liable to any person or entity for any special, incidental, indirect, or consequential damages, including damages resulting from the use or reliance on the information presented. It will also not be responsible for any loss of profits, revenues, or other damages, even if informed in advance of the possibility of such damages.</strong></p>
          <p>Every effort has been made to ensure the accuracy of the information presented.</p>
          <p>Other websites linked to this site are not under the control of H N A & Co. LLP. We are not responsible for the content of any linked site or any link contained within the linked site. H N A & Co. LLP does not endorse companies and/or products to which it links and reserves the right to terminate any link or linked program at any time.</p>
          <h2 className="mt-5 mb-4">Privacy and Security</h2>
          <p>All the personal data collected by H N A & Co. LLP:</p>
          <ul>
            <li>During the course of creation of a client relationship, or</li>
            <li>Voluntarily submitted by a user on this website,</li>
          </ul>
          <p>will be kept confidential and protected by using reasonable technical and organisational measures.</p>
          <p>The main purposes of collecting personal information about individuals are as follows:</p>
          <ul>
            <li>Providing services</li>
            <li>Responding to an individual’s request</li>
            <li>Keeping the contacts informed of the industry & law developments that may be of interest to them, including notifying about seminars and other events we organise</li>
            <li>Recruitment of staff and providing internal services to them</li>
            <li>All other lawful purposes related to our profession and in furtherance to fulfilment of lawful contract.</li>
          </ul>
          <p>Personal information collected by us through this site is used only for the intended purpose stated at the time that the information is collected or for the purposes specified above. However, for the purpose of enabling the dissemination of the Legal Information, the Personal Data shall be shared by H N A & Co. LLP with authorised third parties who provide us with the required technical facility.</p>
          <p>The recipients of the Legal Information may at any time opt out and request H N A & Co. LLP for the deletion or restricted use of their Personal Data from the database. Upon the receipt of such request, H N A & Co. LLP shall with immediate effect delete the Personal Data from its database.</p>
        </div>
      </div>
    </div>
  
    </Layout>
  );
};

export default TermsNConditions;
