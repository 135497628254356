import React from "react";
import {
  ABOUT_IMG01,
  ABOUT_IMG_SHAPE01,
} from "../../lib/assets";

export const AboutOne = () => {
  return (
    <section className="about-area about-bg">
      <div className="container">
        <div className="row align-items-center">

          <div className="col-lg-7">
            <div className="about-content">
              <div className="section-title mb-25 tg-heading-subheading animation-style2">
                <span className="sub-title tg-element-title">
                  WHAT WE DO
                </span>
                <h2 className="title tg-element-title">
                  Transforming Legal Solutions for Your Success
                </h2>
              </div>
              <p>
                H N A Law Chambers, was established with an aim to provide effective solutions to our clients
                backed by our core values of integrity, knowledge and independence (GST, Customs, Foreign
                Trade Policy and erstwhile Central Excise, Service Tax and Value Added Tax).  Our partners have
                experience of 10+ years in the field of indirect taxation. The firm has offices in Bengaluru,
                Hyderabad, Vizag, Chennai, Raipur, Kolkata, Mumbai, Pune, Gurgaon, Ahmedabad. The firm also
                serves clientele from various industries such as manufacturing, information technology, public
                sector undertakings, Governmental authorities etc.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-img-wrap">
              <img src={ABOUT_IMG01} alt="Main" className="main-img" />
              <img src={ABOUT_IMG_SHAPE01} alt="Disclaimer" />
              {/* <img src={ABOUT_IMG_SHAPE02} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
