import React from "react";
import { Link } from "react-router-dom";
import { REQUEST_SHAPE } from "../../lib/assets";

export const RequestAreaOne = () => {
  return (
    <section className="request-area request-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <div className="request-content tg-heading-subheading animation-style1">
              <h2 className="title tg-element-title">
                Career  Opportunities <br />
               
                <p className="text-white fw-light pt-2">
Explore diverse career opportunities in our innovative and dynamic workplace. Join us on a journey of professional growth and fulfillment.</p>
              </h2>
             
            </div>
          </div>
          <div className="col-lg-4">
            <div className="request-content-right">
             
              <div className="request-btn">
                <Link to="/careers" className="btn">
                  Join Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="request-shape">
        <img src={REQUEST_SHAPE} alt="" />
      </div>
    </section>
  );
};
