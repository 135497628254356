import React from "react";
import { PRICING_SHAPE } from "../../lib/assets";

export const PricingAreaThree = () => {

  return (
    <section className="pricing-area-three">
      <div className="pricing-shape">
        <img
          src={PRICING_SHAPE}
          alt=""
          data-aos="fade-right"
          data-aos-delay="200"
        />
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6">
            <div className="section-title-two tg-heading-subheading animation-style2">
              <span className="sub-title tg-element-title" style={{ color: '#0056b3' }}>
              Our Vision
              </span>
              <h2 className="title tg-element-title">
              Striving for Excellence
              </h2>
              <div className="section-top-content mb-30">
              <p>
              To be the preferred professional organisation, trusted to deliver excellence.
              </p>
            </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section-title-two  tg-heading-subheading animation-style3">
              <span className="sub-title" style={{ color: '#0056b3' }}>Our Purpose</span>
              <h2 className="title tg-element-title">
              Adding Professional Value
              </h2>
              <p>
              We believe in adding value while serving business and people, professionally.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};