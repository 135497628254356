import React from "react";
import { Layout } from "../../layouts/Layout";
import { FeatureFour } from "../../components/Features/FeatureFour";
import { AboutSix } from "../../components/About/AboutSix";
import { ServicesThree} from "../../components/Services/ServicesThree";
import { Helmet } from "react-helmet";

const CareerPage = () => {
  return (
    <Layout header={6} footer={1} breadcrumb={"Careers"} title={"Careers"}>
       <Helmet>
        <title>Join Our Team | Careers at HNA Law Chambers</title>
        <meta
          name="description"
          content="Explore exciting career opportunities at H N A Law Chambers. Join our team of legal experts specializing in GST, Customs, and more. Discover how you can contribute to our mission of providing tailored legal solutions. Apply now for a rewarding career in law."
        />
      </Helmet>
     <AboutSix />
      <FeatureFour></FeatureFour>
      <ServicesThree />
    </Layout>
  );
};

export default CareerPage;
