import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { H4_SERVICES_SHAPE } from "../../lib/assets";
import { ServicesThreeItem } from "./ServicesThreeItem";
import axios from "axios";
import { useQuery } from '@tanstack/react-query';

const getCareers = async () => {
  const careers = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/listedjobs`);
  return careers.data ?? [];
};

export const ServicesThree = ({ isServicePage }) => {
  const { data, isLoading } = useQuery({ queryKey: ['careers'], queryFn: getCareers });
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setShowMore(false);
  }, [data, isServicePage]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <section id="servicesThree" className="services-area-three">
      <div className="container pointer">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-6 col-md-8">
            <div className="section-title mb-50 tg-heading-subheading animation-style2">
              <span className="sub-title tg-element-title">
                Current Job Openings
              </span>
              <h2 className="title tg-element-title">
                Discover Exciting Opportunities with Us!
              </h2>
            </div>
          </div>
          {data && data.length > 5 && (
            <div className="col-xl-7 col-lg-6 col-md-4">
              <div className="view-all-btn text-end mb-30">
                <Link to="" className="btn transparent-btn-two" onClick={handleShowMore}>
                  {showMore ? "Show Less Jobs" : "See All Jobs"}
                </Link>
              </div>
            </div>
          )}
        </div>

        <div className="row justify-content-center">
          {isLoading ? (
            "Loading..."
          ) : data && data.length ? (
            data.slice(0, showMore ? data.length : 5).map((service) => (
              <div key={service._id} className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <ServicesThreeItem key={service._id} service={service} />
              </div>
            ))
          ) : (
            "No Jobs Available"
          )}
        </div>
      </div>

      <div className="services-shape-four">
        <img
          src={H4_SERVICES_SHAPE}
          alt=""
          data-aos="fade-left"
          data-aos-delay="200"
        />
      </div>
    </section>
  );
};
