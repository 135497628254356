import React from "react";
import { FEATURE_ONE_LIST } from "../../mocks/features";

export const FeatureFive = () => {
  return (
    <section className="features-area-five features-bg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6">
            <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
              <span className="sub-title tg-element-title">Core Values</span>
              <h2 className="title tg-element-title">Guiding Principles of Excellence</h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {FEATURE_ONE_LIST.map((feature) => (
            <div key={feature.id} className="col-lg-4 col-md-6 pb-3">
              <div className="features-item">
                <div className="features-content">
                  <div className="content-top">
                    <div className="icon">
                      <i className={feature.icon}></i>
                    </div>
                    <h1 className="title">{feature.title} </h1>
                  </div>
                  <p>{feature.subtitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      
    </section>
  );
};
