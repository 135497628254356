import React from "react";
import { Link } from "react-router-dom";
import { SlickSlider } from "../SlickSlider/SlickSlider";
import cn from "classnames";

export const SliderAreaOne = () => {
  const slick_settings_brands = {
    autoplay: false,
    autoplaySpeed: 10000,
    dots: false,
    fade: true,
    arrows: false,
    responsive: [{ breakpoint: 767, settings: { dots: false, arrows: false } }],
  };

  return (
    <section className="slider-area">
      <div className="slider-active ">
        <SlickSlider settings={slick_settings_brands}>
          {["one"].map((item) => (
            <div key={item} className={cn("single-slider slider-bg slider-bg-mobile ", item)}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="slider-content">
                      <span
                        className="sub-title"
                        data-animation="fadeInUp"
                        data-delay=".2s"
                      >
                        Guiding Your Legal Pathway
                      </span>
                      <h2
                        className="title"
                        data-animation="fadeInUp"
                        data-delay=".4s"
                      >
                        Navigating Tax Law Complexity
                      </h2>
                      <p data-animation="fadeInUp" data-delay=".6s">
                      A decade of experience, strategic locations, personalized, integrity-driven indirect tax solutions.
                      </p>
                      <Link
                        to="/about-us"
                        className="btn"
                        data-animation="fadeInUp"
                        data-delay=".8s"
                      >
                        About Us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          ))}
        </SlickSlider>
      </div>
    </section>
  );
};
