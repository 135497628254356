import { z } from "zod";

export const contactSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Invalid email format" }),
  message: z
    .string()
    .min(1, { message: "Message is required" })
    // .regex(/^[A-Za-z0-9\s]+$/, { message: "Message should only contain alphanumeric characters and spaces" })
    .min(10, { message: "Message should be at least 10 characters long" }),
  name: z
    .string()
    .min(1, { message: "Name is required" })
    .min(5, { message: "Name should be at least 5 characters long" })
    .regex(/^[A-Za-z0-9\s]+$/, {
      message: "Name should only contain alphanumeric characters and spaces",
    }),
  organisation: z
    .string()
    .min(1, { message: "Organization is required" })
    .regex(/^[A-Za-z0-9\s]+$/, {
      message:
        "Organization should only contain alphanumeric characters and spaces",
    }),
  phone: z
    .string()
    .min(1, { message: "Phone number is required" })
    .min(10, { message: "Phone number should be at least 10 digits long" })
    .max(15, { message: "Phone number should be at most 15 digits long" })
    .regex(/^\d+$/, {
      message:
        "Phone number should not contain alphabets or special characters",
    }),
  state: z
    .string()
    .min(1, { message: "State is required" })
    .regex(/^[A-Za-z0-9\s]+$/, {
      message: "State should only contain alphanumeric characters and spaces",
    }),
});
