import React from "react";
import {
  H5_ABOUT_IMG
} from "../../lib/assets";

export const AboutSix = () => {
  const scrollToServices = (event) => {
    event.preventDefault(); // Prevent the default behavior of the button click
    const servicesThree = document.getElementById("servicesThree");
    if (servicesThree) {
      const offsetTop = servicesThree.offsetTop - 20; // Adjusted offset
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  return (
    <section className="about-area-six pt-5 pointer">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-img-six">
              <img src={H5_ABOUT_IMG} alt="" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content-six">
              <div className="section-title section-title-three mb-30 tg-heading-subheading animation-style1">
                <span className="sub-title tg-element-title">
                  Dynamic Growth
                </span>
                <h2 className="title tg-element-title">
                 Pursuing Excellence and Economic Justice
                </h2>
              </div>

         
<p>
    HNA Law Chambers was founded on the premise of our purpose to deliver excellence and in the course of it, commit to our ethics & integrity and ensuring economic justice to the trade and industry.
</p>
<p>
    We encourage our people to strive for excellence and innovation within a holistic working environment. From creating a career for you through customized training, HNA Law Chambers provides you with the vibrant exposure you need to develop your career. You will associate with expert professionals in the field of indirect taxation (GST, Customs, Foreign Trade Policy) and associated laws, audit in IDT, strategical reviews, legal and consulting backgrounds to launch your career.
</p>
<div className="text-center">
  <button onClick={scrollToServices} className="btn btn-primary mt-2">
    Click to View Job Openings
  </button>
</div>
          
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
