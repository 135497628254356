import React from "react";
import { Layout } from "../../layouts/Layout";
import { TEAM_DETAILS_IMG, TEAM_DETAILS_IMG1, TEAM_DETAILS_IMG2 } from "../../lib/assets";
import { Helmet } from "react-helmet";

const TeamDetailsPage = () => {
  return (
    <Layout header={6} footer={1} breadcrumb={"Team Details"} title={"Team Details"}>
       <Helmet>
        <title>Our Partners | HNA Law Chambers</title>
        <meta
          name="description"
          content="Discover the collaborative partnerships driving success at H N A Law Chambers. Learn about our knowledgeable network of professionals, firms, and trade associations. Partner with us for comprehensive legal solutions tailored to your needs."
        />
      </Helmet>
      {/* team-details-area */}
      <section className="team-details-area pt-70 pb-70">
        <div className="container">
          <div className="row">
            {/* Partner 1 */}
            <div className="col-lg-4 pt-3">
              <div className="team-details-info-wrap">
                <div className="team-details-thumb">
                  <img src={TEAM_DETAILS_IMG1} alt="Partner 1" />
                </div>
              </div>
            </div>
            <div className="col-lg-8 pt-3">
              <div className="team-details-content">
                <h2 className="title">Adv. Anil Kumar Bezwada</h2>
                <span>B.Com ,ACA, LLB, CMA</span>
                <p className="break-lines">
                  He is a practicing Advocate and is a qualified Chartered Accountant. Also, a qualified Cost & Management Accountant. Post Graduated in Law from Andhra University Campus in Business Laws.

                  He is specialized in consultation, audit and litigation in GST, Customs, Excise, Service Tax including Foreign Trade Policy, EOU’s and SEZ’s. He appears before all GST authorities under Indirect Taxes, Customs, Excise and Service Tax Appellate Tribunal (CESTAT) to represent tax payers.

                  Currently a Director & Managing Committee member of The Vizagapatam Chamber of Commerce & Industry for the term 2020-22 & 2022-24. Also, Director & Managing Committee member of Federation of Andhra Pradesh Chamber of Commerce & Industry (FAPCI).

                  Co-opted Member of Co-ordination Committee of ICITSS and AICITSS of SIRC of ICAI for year 2022-2023.
                </p>
                <div className="team-skill-wrap">
                  <div className="team-details-info">
                    <ul className="list-wrap d-inline-flex">
                      <li className="p-3">
                        <a href="tel:+919989604111">
                          <i className="flaticon-phone-call"></i>+919989604111
                        </a>
                      </li>
                      <li className="p-3">
                        <a href="mailto:anil@hnaindia.com">
                          <i className="flaticon-mail"></i>anil@hnaindia.com
                        </a>
                      </li>
                      <li className="p-3">
                      <a  href="https://maps.app.goo.gl/Rnrq12fMyfWxYG7v7">
                        <i className="flaticon-location"></i>Visakhapatnam
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr className="team-divider" />
            <div className="col-lg-8">
              <div className="team-details-content">
                <h2 className="title">Adv. Venkatanarayan GM</h2>
                <span>B.Com, LLB</span>
                <p className="break-lines">
                  He has graduated in commerce field from Bangalore University and completed LLB from Karnataka State Law University.
                  He has experience of 10+ years in the field of indirect taxes including refunds, compliance assistance under GST, Central excise, Service tax and VAT.
                  He has headed GST reviews, compliance support and refunds for clients under various industries including pharma, logistics, retail trade, agriculture, aerospace, automobile, IT/ ITES,travel and hotel industries etc.
                  He has authored a booklet on “Reverse charge & Joint charge under Service tax” & "Job work implications under Central Excise" published by Hiregange Academy and also regular contributor of articles to newsletter published at Hiregange Academy and Excise law times published by Centax publications.
                </p>
                <div className="team-skill-wrap">
                  <div className="team-details-info">
                    <ul className="list-wrap d-inline-flex">
                      <li className="p-3">
                        <a href="tel:+919620116162">
                          <i className="flaticon-phone-call"></i>+919620116162
                        </a>
                      </li>
                      <li className="p-3">
                        <a href="mailto:venkat@hnaindia.com">
                          <i className="flaticon-mail"></i>venkat@hnaindia.com
                        </a>
                      </li>
                      <li className="p-3">
                      <a  href="#">
                        <i className="flaticon-location"></i>Bengaluru
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="team-details-info-wrap">
                <div className="team-details-thumb">
                  <img src={TEAM_DETAILS_IMG} alt="Partner 1" />
                </div>
              </div>
            </div>
            <hr className="team-divider" />
            {/* Partner 3 */}
            <div className="col-lg-4 pt-3">
              <div className="team-details-info-wrap">
                <div className="team-details-thumb">
                  <img src={TEAM_DETAILS_IMG2} alt="Partner 2" />
                </div>
              </div>
            </div>
            <div className="col-lg-8 pt-3">
              <div className="team-details-content">
                <h2 className="title">Adv. Venkata Prasad Pasupuleti</h2>
                <span>B.Com, FCA, LLB</span>
                <p className="break-lines">
                A law graduate from Osmania University and Practicing Advocate at High court. He is also Chartered Accountant and was a Partner in Hiregange & Associates LLP before starting of advocate practice. He has cleared ‘Certificate course on IBC’ conducted by ICAI.   
                He regularly appears before High court of Telangana and Andhra Pradesh, CESTAT and various Tax authorities and represented 1200+ clients before various forums.
                He is regular speaker at ICAI, NACIN (training wing of Central GST), FIEO and various other trade & professional forums for more than 150+ sessions on various topics of Indirect tax.
                He is an active contributor of articles in ICAI Hyderabad monthly newsletter, GST law times, Taxguru, TMI, Caclubindia, and other professional forums. He has written 100+ articles written on indirect taxes.
                He authorised a book on ‘GST Judicial precedents’ published by ‘Taxsutra’ and been part of various books written on indirect taxes.
                </p>
                <div className="team-skill-wrap">
                  <div className="team-details-info">
                    <ul className="list-wrap d-inline-flex">
                      <li className="p-3">
                        <a href="tel:+919620116162">
                          <i className="flaticon-phone-call"></i>+918978114341
                        </a>
                      </li>
                      <li className="p-3">
                        <a href="mailto:venkat@hnaindia.com">
                          <i className="flaticon-mail"></i>venkataprasad@hnaindia.com 
                        </a>
                      </li>
                      <li className="p-3">
                      <a href="https://maps.app.goo.gl/eiP3N6LF275nWpJ48">
                        <i className="flaticon-location"></i>Hyderabad 
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TeamDetailsPage;