import React from "react";
import {
  H4_ABOUT_IMG01,
} from "../../lib/assets";

export const AboutTen = () => {
  return (
    <section className="about-area-ten">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="about-img-wrap-five about-img-wrap-ten">
              <img src={H4_ABOUT_IMG01} alt="" />
          
              <div className="experience-wrap">
                <h2 className="title">
                  HNA <span>LAW CHAMBERS</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content-five about-content-ten">
              <div className="section-title-two mb-30 tg-heading-subheading animation-style1">
                <span className="sub-title tg-element-title">Who Are We</span>
                <h2 className="title tg-element-title">
                Your Premier Partner in Indirect Taxation
                </h2>
              </div>
              <p>
              At H N A Law Chambers, our commitment to delivering optimal solutions to clients is fortified by integrity, knowledge, and independence. With over 10 years of collective experience in GST, Customs, and more, our distinguished partners provide comprehensive and strategic legal counsel.
              </p>
              <p>
              Strategically located offices across India ensure seamless service tailored to regional nuances, making us your trusted ally in navigating the complex landscape of indirect taxation. We're not just a legal firm; we're dedicated to excellence and client satisfaction, evolving with the dynamic legal landscape to meet your needs effectively.
              </p>
              <div className="about-success-wrap">
                <ul className="list-wrap">
                  <li>
                    <div className="icon">
                      <i className="flaticon-family"></i>
                    </div>
                    <div className="content">
                      
                      <p>Strategic Legal Counsel</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="flaticon-trophy"></i>
                    </div>
                    <div className="content">
                      
                      <p>Indirect Taxation Expertise </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

   
    </section>
  );
};
