import { AboutThree } from "../../components/About/AboutThree";
import { ChooseAreaOne } from "../../components/ChooseAreas/ChooseAreaOne";
import { ServicesTwo } from "../../components/Services/ServicesTwo";
import { AboutOne } from "../../components/About/AboutOne";
import { SliderAreaOne } from "../../components/SliderAreas/SliderAreaOne";
import { Layout } from "../../layouts/Layout";
import { RequestAreaOne } from "../../components/RequestAreas/RequestAreaOne";
import { PricingAreaThree } from "../../components/PricingAreas/PricingAreaThree";

const Home = () => {
  return (
    
    <Layout header={1} footer={1}>
      <SliderAreaOne />
      <AboutOne />
      
      <ServicesTwo />
     <ChooseAreaOne />
     <AboutThree />
      <RequestAreaOne />
      <PricingAreaThree />
    </Layout>
  );
};

export default Home;