import React from "react";
import { Layout } from "../../layouts/Layout";
import { AboutTen } from "../../components/About/AboutTen";
import { ChooseAreaThree } from "../../components/ChooseAreas/ChooseAreaThree";
import { TeamAreaFour } from "../../components/TeamAreas/TeamAreaFour";
import { FeatureFive } from "../../components/Features/FeatureFive";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <Layout header={6} footer={1} breadcrumb={"About"}  title={'About us'}>
       <Helmet>
        <title>About Us: Crafting Legal Excellence at H N A Law Chambers</title>
        <meta
          name="description"
          content="Discover H N A Law Chambers, where expertise meets innovation in legal services. From GST to Customs, we provide tailored solutions with professionalism and precision. Explore our unique approach today."
        />
      </Helmet>
      {/* about-area */}
      <AboutTen />
      <FeatureFive />
      {/* choose-area */}
      <ChooseAreaThree />

      {/* team-area */}
      <TeamAreaFour />

     

      
     
    </Layout>
  );
};

export default AboutUs;
