import {
  H2_SERVICES_IMG01,
  H2_SERVICES_IMG02,
  H2_SERVICES_IMG03,
  H2_SERVICES_IMG04,
  H2_SERVICES_IMG05,
  H2_SERVICES_IMG06,
  SERVICES_IMG01,
  SERVICES_IMG02,
  SERVICES_IMG03,
  SERVICES_ITEM_SHAPE,
} from "../lib/assets";

export const SERVICES_TWO_LIST = [
  {
    id: 1,
    src: H2_SERVICES_IMG01,
    alt: "Integrity image",
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-round-table",
    title: "Integrity",
    subtitle: "Doing the right things in a fair & ethical manner.",
  },
  {
    id: 2,
    src: H2_SERVICES_IMG02,
    alt: "Knowledge image",
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-business-presentation",
    title: "Knowledge",
    subtitle: "Acquire and share subject expertise continuously.",
  },
  {
    id: 3,
    src: H2_SERVICES_IMG03,
    alt: "Independence image",
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-family-insurance",
    title: "Independence",
    subtitle: "Being Unbiased.",
  },
  {
    id: 4,
    src: H2_SERVICES_IMG04,
    alt: "Innovation image",
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-rocket",
    title: "Innovation",
    subtitle: "Thinking originally & differently. Out of the box approach.",
  },
  {
    id: 5,
    src: H2_SERVICES_IMG05,
    alt: "Passion image",
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-conflagration",
    title: "Passion",
    subtitle: "Inspired to make a difference.",
  },
  {
    id: 6,
    src: H2_SERVICES_IMG06,
    alt: "Social Responsibility image",
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-handshake",
    title: "Social Responsibility",
    subtitle: "Focus on education and training of community.",
  },
];
;

export const SERVICES_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-business-presentation",
    href: "/services-details",
    title: "Marketing Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-rocket",
    href: "/services-details",
    title: "SEO Consultancy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-mission",
    href: "/services-details",
    title: "Target Audience",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];

export const SERVICES_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-briefcase",
    href: "/services-details",
    src: SERVICES_IMG01,
    title: "Business Analysis",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Tax Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    href: "/services-details",
    src: SERVICES_IMG03,
    title: "Financial Advice",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 4,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Tax Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];
