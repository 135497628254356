import React from "react";

export const ChooseAreaOne = () => {

  return (
    <section className="choose-area jarallax choose-bg">
      <div className="choose-shape"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="choose-content">
              <div className="section-title-two white-title mb-20 tg-heading-subheading animation-style3">
                <h2 className="title tg-element-title">
                  We're Dedicated to Providing the Best Guidance.
                </h2>
              </div>
              <p>
                At H N A Law Chambers, our priority is to ensure that you
                receive top-notch guidance every step of the way. With our
                expertise and commitment, rest assured you're in capable hands.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="skill-wrap"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <div className="section-title-two mb-15">
                <span className="sub-title" style={{ color: '#0056b3' }}>Our Mission</span>
                <h2 className="title">
                  Pursuing Excellence Through Commitment
                </h2>
              </div>
              <p>We achieve this through:</p>
              <div className="progress-wrap">
                <div className="progress-item">
                  <h4 className="title">Consistent, high-quality services</h4>
                  <div
                    className="progress"
                    role="progressbar"
                    aria-label="Example with label"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className="progress-bar"
                      data-aos="slide-left"
                      data-aos-delay="100"
                      style={{
                        width: "100%",
                      }}
                    >
                      <span>Excellence</span>
                    </div>
                  </div>
                </div>
                <div className="progress-item">
                  <h4 className="title">Practical problem-solving</h4>
                  <div
                    className="progress"
                    role="progressbar"
                    aria-label="Example with label"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className="progress-bar"
                      data-aos="slide-left"
                      data-aos-delay="200"
                      style={{ width: "100%" }}
                    >
                      <span>Problem Solving</span>
                    </div>
                  </div>
                </div>
                <div className="progress-item">
                  <h4 className="title">Responsibility and versatility</h4>
                  <div
                    className="progress"
                    role="progressbar"
                    aria-label="Example with label"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className="progress-bar"
                      data-aos="slide-left"
                      data-aos-delay="300"
                      style={{ width: "100%" }}
                    >
                      <span>Adaptability</span>
                    </div>
                  </div>
                </div>
                <div className="progress-item">
                  <h4 className="title">Developing knowledgeable leaders</h4>
                  <div
                    className="progress"
                    role="progressbar"
                    aria-label="Example with label"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className="progress-bar"
                      data-aos="slide-left"
                      data-aos-delay="300"
                      style={{ width: "100%" }}
                    >
                      <span>Leadership</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
