import { z } from "zod";

export const careersSchema = z.object({
  name: z.string()
    .min(5, { message: "Name should be at least 5 characters long" })
    .min(1, { message: "Name is required" })
    .regex(/^[a-zA-Z0-9\s.,()-]*$/, { message: "Name should only contain alphanumeric characters, spaces, brackets & dashes" }),
    date_of_birth: z.string()
    .min(1, { message: "Date of Birth is required" })
    .refine(val => {
      const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
      return dateRegex.test(val);
    }, { message: "Date should be in correct format" }),
    gender: z.string().nullable().refine(val => val !== null && val !== "", { message: "Gender is required" }),
    marital_status: z.string().nullable().refine(val => val !== null && val !== "", { message: "Marital Status is required" }),
  qualification: z.string()
    .min(1, { message: "Qualification is required" })
    .regex(/^[a-zA-Z0-9\s.,()-]*$/, { message: "Qualification should only contain alphanumeric characters, spaces, brackets & dashes" }),
  experience_in_years: z.string()
    .min(1, { message: "Experience is required" })
    .regex(/^[a-zA-Z0-9\s.,()-]*$/, { message: "Experience should only contain alphanumeric characters, spaces, brackets & dashes" }),
  areas_of_exposure: z.string()
    .min(1, { message: "Areas of Exposure is required" })
    .regex(/^[a-zA-Z0-9\s.,()-]*$/, { message: "Areas of Exposure should only contain alphanumeric characters, spaces, brackets & dashes" }),
  key_skills: z.string()
  .min(1, { message: "Key Skills is required" })
  .regex(/^[a-zA-Z0-9\s.,()-]*$/, { message: "Key Skills should only contain alphanumeric characters, spaces, brackets & dashes" }),
  email: z.string()
  .min(1, { message: "Email is required" })
  .email({ message: "Invalid email format" }),
  phone: z.string()
    .min(10, { message: "Phone number should be at least 10 digits long" })
    .max(15, { message: "Phone number should be at most 15 digits long" })
    .regex(/^\d+$/, { message: "Phone number should only contain digits" })
    .min(1, { message: "Phone number is required" }),
  current_location: z.string()
    .min(1, { message: "Current Location is required" })
    .regex(/^[a-zA-Z0-9\s.,()-]*$/, { message: "Current Location should only contain alphanumeric characters, spaces, brackets & dashes" }),
  preferred_location1: z.string().min(1, { message: "Preferred Location 1 is required" }),
  preferred_location2: z.string().optional(),
  preferred_location3: z.string().optional(), 
  address: z.string()
    .min(10, { message: "Address should be at least 10 characters long" })
    .min(1, { message: "Address is required" })
    .regex(/^[a-zA-Z0-9\s.,()-]*$/, { message: "Address should only contain alphanumeric characters, spaces, brackets & dashes" }),
  resume: z
  .custom((value) => {
    if (!value || value.length === 0) {
      return "Resume is required";
    }
    // Check if file type is allowed
    const allowedFileTypes = [".pdf", ".doc", ".docx"];
    const fileType = value[0].name.split(".").pop().toLowerCase();
    if (!allowedFileTypes.includes(`.${fileType}`)) {
      return "Only PDF and Word documents are allowed";
    }
    return value;
  })
  .refine((value) => value.length === 1, {
    message: "Only one file can be uploaded",
  }),
});
