export const locations = [
    { label: "Bengaluru", value: "Bengaluru" },
    { label: "Hyderabad", value: "Hyderabad" },
    { label: "Chennai", value: "Chennai" },
    { label: "Pune", value: "Pune" },
    { label: "NCR (Gurgaon)", value: "NCR (Gurgaon)" },
    { label: "Mumbai", value: "Mumbai" },
    { label: "Raipur", value: "Raipur" },
    { label: "Vishakapatnam", value: "Vishakapatnam" },
    { label: "Kolkata", value: "Kolkata" },
    { label: "Ahmedabad", value: "Ahmedabad" }
  ];
  