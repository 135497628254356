import { RIGHT_ARROW } from "../lib/assets";

export const FEATURE_TWO_LIST = [
  {
    id: 1,
    icon: "flaticon-profit",
    title: "Growing Business",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 2,
    icon: "flaticon-investment",
    title: "Finance Investment",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 3,
    icon: "flaticon-taxes",
    title: "Tax Advisory",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
];

export const FEATURE_THREE_LIST = [
  {
    id: 1,
    title: "Goods and Services Tax",
    subtitle:
      "New Tax regime introduced in India in 2017. Replaced multiple indirect tax laws, for levy of tax on goods and services.",
    href: "",
    src: RIGHT_ARROW,
  },
  {
    id: 2,
    title: "Customs",
    subtitle:
      "Law regulating levy of duties of Customs on importation of goods in India including export duties.",
    href: "",
    src: RIGHT_ARROW,
  },
  {
    id: 3,
    title: "Foreign Trade Policy",
    subtitle:
      "Concerned with development & regulation of foreign trade by facilitating imports & augmenting exports from India.",
    href: "",
    src: RIGHT_ARROW,
  },
  {
    id: 4,
    title: "Erstwhile Indirect Taxes",
    subtitle:
      "Central Excise, Service Tax, State VAT Laws and CST on different stages of commercial activities.",
    href: "s",
    src: RIGHT_ARROW,
  },
  {
    id: 5,
    title: "Knowledge Partner",
    subtitle:
      "Knowledge Partner for other professionals, firms and trade associations.",
    href: "",
    src: RIGHT_ARROW,
  },
  {
    id: 6,
    title: "Trade Compliance and Regulations",
    subtitle:
      "Ensuring Adherence to Laws Governing International Trade, Customs Duties, and GST.",
    href: "",
    src: RIGHT_ARROW,
  },
];

export const FEATURE_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-life-insurance",
    title: "Weekly Skills Boost",
    subtitle: "Continuous development through weekly soft skills and industry-specific training sessions."
  },
  {
    id: 2,
    icon: "flaticon-handshake",
    title: "Values-Aligned Career",
    subtitle: "Guiding careers in harmony with HNA's core values for personal and professional fulfillment."
  },
  {
    id: 3,
    icon: "flaticon-money",
    title: "Presentation Opportunities",
    subtitle: "Showcase your expertise at internal meetings, and external events."
  },
  {
    id: 4,
    icon: "flaticon-briefcase",
    title: "Diverse Client Exposure",
    subtitle: "Navigating various client environments, enriching experiences for comprehensive skill development."
  },
  {
    id: 5,
    icon: "flaticon-padlock",
    title: "Ethical Practice",
    subtitle: "Promote ethical standards through a strong Code of Conduct, cultivating a culture of integrity and accountability."
  },
  {
    id: 6,
    icon: "flaticon-heart",
    title: "Social Contribution",
    subtitle: "Participate in voluntary initiatives, making a positive impact on society through your professional and personal contributions."
  }
];


export const FEATURE_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-puzzle-piece",
    title: "Integrity",
    subtitle: "Doing the right things in a fair & ethical manner.",
  },
  {
    id: 2,
    icon: "flaticon-inspiration",
    title: "Knowledge",
    subtitle: "Acquire and share subject expertise continuously.",
  },
  {
    id: 3,
    icon: "flaticon-profit",
    title: "Innovation",
    subtitle: "Thinking originally and differently. Out-of-the-box approach.",
  },
  {
    id: 4,
    icon: "flaticon-trophy",
    title: "Passion",
    subtitle: "Inspired to make a difference.",
  },
  {
    id: 5,
    icon: "flaticon-family",
    title: "Independence",
    subtitle: "Being unbiased.",
  },
  {
    id: 6,
    icon: "flaticon-handshake",
    title: "Social responsibility",
    subtitle: "Focuses on education and training.",
  },
];
