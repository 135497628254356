import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Layout } from "./../layouts/Layout";
import { zodResolver } from "@hookform/resolvers/zod";
import { careersSchema } from "../components/careersSchema";
import { locations } from '../lib/constants';
import { useLocation } from "react-router-dom";
import axios from "axios";

export const ApplyForm = () => {
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
    resolver: zodResolver(careersSchema)
  });

  const [submissionMessage, setSubmissionMessage] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);
  const [formOpacity, setFormOpacity] = useState(1);
  const [serviceData, setServiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/listedjobs/${id}`);
        const jobData = response.data;
        if (jobData.publishStatus !== 'Publish') {
          setErrorMessage("This job is no longer available.");
        } else {
          setServiceData(jobData);
        }
      } catch (error) {
        console.error('Error fetching service data:', error);
      } finally {
        setLoading(false);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const Loader = () => (
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        if (key === 'resume') {
          formData.append(key, data[key][0]);
        } else {
          formData.append(key, data[key]);
        }
      });

      const [day, month, year] = data.date_of_birth.split('/');
      const dateOfBirth = new Date(`${year}-${month}-${day}`);
      if (isNaN(dateOfBirth)) {
        throw new Error('Invalid date of birth');
      }
      formData.append('date_of_birth', dateOfBirth.toISOString().split('T')[0]);
      formData.append('position', serviceData.position);

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/submitApplication`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setSubmissionMessage("Thank you! Your application has been submitted successfully.");
      setFormDisabled(true);
      setFormOpacity(0.3);
    } catch (error) {
      console.error('Error submitting application:', error);
      setSubmissionMessage("Sorry, there was an error submitting your application. Please try again later.");
    }
  };


  return (
    <Layout header={6} footer={1} breadcrumb={"Apply Now"} title={'Application Form'}>
      <div className="container mx-auto p-4 pointer">
        <div className="row align-items-center">
          <div className="section-title-two mb-30 tg-heading-subheading mt-4 animation-style2 ">
            <div className="row align-items-center ">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <span className="sub-title tg-element-title">Current Job Opening</span>
                {errorMessage ? (
                  <p className="text-danger">{errorMessage}</p>
                ) : (
                  serviceData ? (
                    <>
                      <div>
                        <h2 className="title tg-element-title">{serviceData.position}</h2>
                        <p className="w-100">{serviceData.content}</p>
                        <p><strong>Required Qualification: </strong>{serviceData.qualification}</p>
                        <p><strong>Vacancies: </strong>{serviceData.city}</p>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)} className={`bg-white pt-5 rounded-md ${submissionMessage ? '' : ''}`} noValidate style={{ opacity: formOpacity }}>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="name">Name:</label>
                            <input className="form-control" id="name" {...register("name", { required: true, minLength: 5, maxLength: 100 })} type="text" placeholder="Enter Your Name" disabled={formDisabled} />
                            {errors.name && <p className="text-danger mb-0">{errors.name.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="date_of_birth">Date of Birth:</label>
                            <input
                              className="form-control"
                              id="date_of_birth"
                              {...register('date_of_birth', {
                                required: true,
                                pattern: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
                              })}
                              type="text"
                              placeholder="dd/mm/yyyy"
                              disabled={formDisabled}
                              onKeyDown={(e) => {
                                const key = e.key;
                                const value = e.target.value;
                                const selectionStart = e.target.selectionStart;
                                if (key === 'Backspace') {
                                  if (value.charAt(selectionStart - 1) === '/') {
                                    e.target.value = value.slice(0, selectionStart - 1) + value.slice(selectionStart);
                                    e.target.setSelectionRange(selectionStart - 1, selectionStart - 1);
                                  }
                                }
                              }}
                              onChange={(e) => {
                                let value = e.target.value;
                                const selectionStart = e.target.selectionStart;
                                if (value.length === 2 || value.length === 5) {
                                  value += '/';
                                } else if (value.length === 3 || value.length === 6) {
                                  if (value.charAt(value.length - 1) !== '/') {
                                    e.target.setSelectionRange(selectionStart + 1, selectionStart + 1);
                                  }
                                }
                                e.target.value = value;
                              }}
                              maxLength={10}
                            />
                            {errors.date_of_birth && <p className="text-danger  mb-0">{errors.date_of_birth.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory">Gender:</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{ marginRight: '20px' }}>
                                <input className="form-check-input" type="radio" id="male" value="Male" {...register("gender", { required: true })} disabled={formDisabled} />
                                <label className="form-check-label ms-2" htmlFor="male">Male</label>
                              </div>
                              <div>
                                <input className="form-check-input" type="radio" id="female" value="Female" {...register("gender", { required: true })} disabled={formDisabled} />
                                <label className="form-check-label ms-2" htmlFor="female">Female</label>
                              </div>
                            </div>
                            {errors.gender && <p className="text-danger mb-0">{errors.gender.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory"><strong>Marital Status:</strong></label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{ marginRight: '20px' }}>
                                <input className="form-check-input " type="radio" id="single" value="Single" {...register("marital_status", { required: true })} disabled={formDisabled} />
                                <label className="form-check-label ms-2" htmlFor="single">Single</label>
                              </div>
                              <div>
                                <input className="form-check-input" type="radio" id="married" value="Married" {...register("marital_status", { required: true })} disabled={formDisabled} />
                                <label className="form-check-label ms-2" htmlFor="married">Married</label>
                              </div>
                            </div>
                            {errors.marital_status && <p className="text-danger mb-0">{errors.marital_status.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="qualification">Qualification:</label>
                            <input className="form-control" id="qualification" {...register("qualification", { required: true, maxLength: 100 })} type="text" placeholder=" Enter Qualification" disabled={formDisabled} />
                            {errors.qualification && <p className="text-danger mb-0">{errors.qualification.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="experience_in_years">Experience in Years:</label>
                            <input className="form-control" id="experience_in_years" {...register("experience_in_years", { required: true, maxLength: 100 })} type="text" placeholder="Enter Experience in Years" disabled={formDisabled} />
                            {errors.experience_in_years && <p className="text-danger mb-0">{errors.experience_in_years.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="areas_of_exposure">Areas of Exposure:</label>
                            <input className="form-control" id="areas_of_exposure" {...register("areas_of_exposure", { required: true, maxLength: 100 })} type="text" placeholder="Enter Areas of Exposure" disabled={formDisabled} />
                            {errors.areas_of_exposure && <p className="text-danger mb-0">{errors.areas_of_exposure.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="key_skills">Key Skills:</label>
                            <input className="form-control" id="key_skills" {...register("key_skills", { required: true, maxLength: 100 })} type="text" placeholder="Enter Key Skills" disabled={formDisabled} />
                            {errors.key_skills && <p className="text-danger mb-0">{errors.key_skills.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="email">Email:</label>
                            <input className="form-control" id="email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} type="email" placeholder="Enter Email" disabled={formDisabled} />
                            {errors.email && <p className="text-danger mb-0">{errors.email.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="phone">Phone:</label>
                            <input className="form-control" id="phone" {...register("phone", { required: true, minLength: 10, maxLength: 15, pattern: /^[0-9]+$/ })} type="tel" placeholder="Enter Phone" disabled={formDisabled} />
                            {errors.phone && <p className="text-danger mb-0">{errors.phone.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="current_location">Current Location:</label>
                            <input className="form-control" id="current_location" {...register("current_location", { required: true, maxLength: 100 })} type="text" placeholder="Enter Current Location" disabled={formDisabled} />
                            {errors.current_location && <p className="text-danger mb-0">{errors.current_location.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="preferred_location1">Preferred Location 1:</label>
                            <select className="form-select" id="preferred_location1" {...register("preferred_location1", { required: true })} disabled={formDisabled} >
                              <option value="">Select Preferred Location</option>
                              {locations.map((location, index) => (
                                <option key={index} value={location.value}>{location.label}</option>
                              ))}
                            </select>
                            {errors.preferred_location1 && <p className="text-danger mb-0">{errors.preferred_location1.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label" htmlFor="preferred_location2">Preferred Location 2:</label>
                            <select className="form-select" id="preferred_location2" {...register("preferred_location2")} disabled={formDisabled} >
                              <option value="">Select Preferred Location</option>
                              {locations.map((location, index) => (
                                <option key={index} value={location.value}>{location.label}</option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label" htmlFor="preferred_location3">Preferred Location 3:</label>
                            <select className="form-select" id="preferred_location3" {...register("preferred_location3")} disabled={formDisabled}>
                              <option value="">Select Preferred Location</option>
                              {locations.map((location, index) => (
                                <option key={index} value={location.value}>{location.label}</option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label mandatory" htmlFor="address">Address:</label>
                            <textarea className="form-control" id="address" {...register("address", { required: true })} rows="3" placeholder="Enter  Address" disabled={formDisabled}></textarea>
                            {errors.address && <p className="text-danger mb-0">{errors.address.message}</p>}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label mandatory" htmlFor="resume">Resume:</label>
                            <input className="form-control" id="resume" {...register("resume", { required: true })} type="file" accept=".pdf,.doc,.docx" disabled={formDisabled} />
                            {errors.resume && <p className="text-danger mb-0">Please upload your resume.</p>}
                          </div>
                        </div>
                        <div className="mb-3 mt-3">
                          <button className="btn btn-primary" type="submit" disabled={isSubmitting || formDisabled} style={{ opacity: formDisabled ? 0.5 : 1 }}>Apply Now</button>
                        </div>
                      </form>
                    </>
                  ) : (
                    <p>{loading ? "Loading..." : "Job not found."}</p>
                  )
                )}
              </div>
              {isSubmitting && (
  <div className="d-flex justify-content-center">
    <Loader />
  </div>
)}
              {submissionMessage && (
                <div className={`alert alert-${submissionMessage.includes("error") ? "danger" : "success"} mt-4`} role="alert">
                  <strong>{submissionMessage.includes("error")}</strong>
                  {submissionMessage}
                </div>
              )}
              
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ApplyForm;
