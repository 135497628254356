import React from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

export const NavMenus = () => {
  const { pathname } = useLocation();

  const isActiveCn = (path) => {
    return path === pathname ? "active" : "";
  };

  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        {/* home */}
        <li className={cn(isActiveCn("/"))}>
          <Link to="/">Home</Link>
        </li>
        <li className="menu-item-has-children">
        <Link to="#">About</Link>
          <ul className="sub-menu">
          <li className={cn(isActiveCn("/about-us"))}>
              <Link to="/about-us">About Us</Link>
            </li>
            <li className={cn(isActiveCn("/partner-details"))}>
              <Link to="/partner-details">Partner Details</Link>
            </li>
            </ul>
        <div className="dropdown-btn">
          <span className="fas fa-angle-down"></span>
          </div>
          </li>
        {/* pages */}
        <li className={cn(isActiveCn("/services"))}>
          <Link to="/services">Services</Link>
        </li>

        {/* contact */}
        <li className={cn(isActiveCn("/careers"))}>
          <Link to="/careers">Careers</Link>
        </li>
      </ul>
    </div>
  );
};
