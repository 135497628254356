import React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../../layouts/Layout";
import { ServiceSeven } from "../../components/Services/ServiceSeven";
import { AboutNine } from "../../components/About/AboutNine";

const ServicesPage = () => {
  return (
    <Layout header={6} footer={1} breadcrumb={"Services"} title={'Our Services'}>
      {/* Meta tags for this page */}
      <Helmet>
        <title>Expert Legal Services | HNA Law Chambers</title>
        <meta
          name="description"
          content="Get tailored legal solutions for GST, Customs, and more at H N A Law Chambers. From industry representations to tax structuring, trust us for comprehensive legal services tailored to your needs."
        />
      </Helmet>

      {/* services */}
      <ServiceSeven />
      <AboutNine />
    </Layout>
  );
};

export default ServicesPage;
