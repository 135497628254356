import React from "react";
import { Link } from "react-router-dom";
import { SERVICES_TWO_LIST } from "../../mocks/services";
import { ServicesTwoItem } from "./ServicesTwoItem";

export const ServicesTwo = () => {
  return (
    <section className="services-area-two services-bg-two">
      <div className="container">
        <div className="services-two-wrapper"> {/* Add a unique class here */}
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-8">
              <div className="section-title-two mb-60 tg-heading-subheading animation-style3">
                <span className="sub-title" style={{ color: '#0056b3' }}>Our Core Values</span>
                <h2 className="title tg-element-title"  style={{ color: '#333' }}>
                  Guiding Principles for Excellence in Legal Practice
                </h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-4">
              <div className="view-all-btn text-end mb-30">
                <Link to="/services" className="btn" style={{ color: '#fff', backgroundColor: '#0056b3' }}>
                  Explore Services
                </Link>
              </div>
            </div>
          </div>

          <div className="row services-row"> {/* Add a unique class here */}
            {SERVICES_TWO_LIST.map((service) => (
              <div key={service.id} className="col-lg-2 col-md-4 col-sm-6 col-6 mb-2">
                <ServicesTwoItem service={service} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};