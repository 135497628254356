import React from "react";
import { Layout } from "../../layouts/Layout";
import { ContactAreaInner } from "../../components/ContactAreas/ContactAreaInner";
import { ContactAreaOne } from "../../components/ContactAreas/ContactAreaOne";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <Layout header={6} footer={1} breadcrumb={"Contact"} title={"Contact Us"}>
       <Helmet>
        <title>Connect with Legal Experts | HNA Law Chambers</title>
        <meta
          name="description"
          content="Seeking expert legal counsel? Contact H N A Law Chambers today. Our team specializes in GST, Customs, and more. Get tailored solutions for your legal needs. Reach out now for comprehensive representation services."
        />
      </Helmet>
      {/* contact-area */}
      <ContactAreaInner />
      <ContactAreaOne />
      {/* map */}
    </Layout>
  );
};

export default ContactPage;
