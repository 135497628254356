import React from "react";
import { FEATURE_THREE_LIST } from "../../mocks/features";
import { FeatureThreeItem } from "../Features/FeatureThreeItem";

export const ServiceSeven = () => {
  return (
    <section className="services-area-seven pt-70 pb-70">
      <div className="container">
        <div className="row ">
          <div className="col-xl-12 col-lg-12">
            <div className="section-title-two tg-heading-subheading animation-style2">
              <span className="sub-title tg-element-title">Practice Areas</span>
              <h2 className="title tg-element-title">
              Comprehensive Legal Solutions
              </h2>
              <p>
              Discover our diverse legal expertise at H N A Law Chambers. From GST to Customs and more, we offer precise solutions tailored to your needs.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {[...FEATURE_THREE_LIST].map((feature) => (
            <div key={feature.id} className="col-xl-4 py-2 col-lg-4 col-md-6">
              <FeatureThreeItem feature={feature} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
