import React, { useState } from "react";
import { ContactAreaMap } from "../../components/ContactAreas/ContactAreaMap";

export const ContactAreaInner = () => {
  const [openContactIndex, setOpenContactIndex] = useState(null); // State to track the index of the open contact div
  const [openMainContact, setOpenMainContact] = useState(null);
  const officeLocations = [
    {
      country: "Hyderabad",
      address: "4th Floor, West Block, Srida Anushka Pride, Beside SBI Bank, above Lawrence & Mayo store, Road No.12, Banjara Hills - 500034.",
      directions: "https://maps.app.goo.gl/jEiLZjD9shZbTAmKA",
      phone: "040-23318128 / +91 99081 13795",
      email: "sudhir@hnaindia.com"
    },
    {
      country: "Chennai",
      address: "Old No.319, New No.04, First Floor Valluvarkottam High Road, Nungambakkam, Chennai – 600034. Above Federal Bank.",
      directions: "https://maps.app.goo.gl/khebF2UAyRVj7Upm7",
      phone: "+91 99625 08380 / 044 3562 3326",
      email: "vikram@hnaindia.com"
    },
    {
      country: "Pune",
      address: "K Square, Office No 206, 207 and 208, Second Floor, Foundry Preschool Rd, Baner, Pune, Maharashtra - 411045.",
      directions: "https://maps.app.goo.gl/3raPUYKzAcmXmWgr8",
      phone: "+91 76800 00205 / 020-29910717",
      email: "ravikumar@hnaindia.com"
    },
    {
      country: "NCR (Gurgaon)",
      address: "202-203, Vipul Trade Centre, Sector 48, Sohna Road, Gurugram, Haryana - 122009.",
      directions: "https://maps.app.goo.gl/RuJrzfb7bRgLbFR16",
      phone: "0124-4904994 / +91 85109 50400",
      email: "ashish@hnaindia.com"
    },
    {
      country: "Mumbai",
      address: "409, Filix, Opp. Asian Paints, LBS Marg, Bhandup West, Mumbai - 400078.",
      directions: "https://maps.app.goo.gl/j5fqahxjB9NLin8P8",
      phone: "+22 2595 5544 / +22 2595 5533 / +91 98673 07715",
      email: "vasant.bhat@hnaindia.com"
    },
    {
      country: "Raipur",
      address: "Office 503-504, Babylon Capital, VIP Chowk Raipur, Chhattisgarh - 492001.",
      directions: "https://maps.app.goo.gl/Q7xkps9QEdf54tkZ9",
      phone: "+91 77135 00251 / +91 74157 90391",
      email: "bhaveshmittal@hnaindia.com"
    },
    {
      country: "Vishakapatnam",
      address: "D.No 8-1-112, Premier House, 2nd Floor, Vidyanagar, Opp.III Town Police Station, Pedda Waltair, Visakhapatnam-530017.",
      directions: "https://maps.app.goo.gl/gtjJEMoYvmtswfxu6",
      phone: "+91 89160 09235",
      email: "anil@hnaindia.com"
    },
    {
      country: "Kolkata",
      address: "Unit#1009, 10th Floor, Merlin Infinite DN- 51, Street Number 11, DN Block Salt Lake Sector, Kolkata, West Bengal - 700091.",
      directions: "https://maps.app.goo.gl/AvqjEfDF7bbpsbyG9",
      phone: "+33 4063 7186 / +91 98306 82188",
      email: "gagan@hnaindia.com"
    },
    {
      country: "Ahemdabad",
      address: "Mauryansh Elanza 908, 9th Floor, Nr. Parekh's Hospital, Shyamal Crossroad, 132 Feet Ring Rd, Ahmedabad, Gujarat- 380015.",
      directions: "https://maps.app.goo.gl/cnBGgVW2VtJsEkbH8",
      phone: "+91 94091 72331 / +91 79460 24716",
      email: "yash@hnaindia.com"
    }
  ];

  const handleIconClick = (type, content, index) => {
    if (type === "email") {
      window.location.href = `mailto:${content}`;
    } else if (type === "phone") {
      if (index === 'main') {
        setOpenMainContact(openMainContact === 'card' ? null : 'card');
      } else {
        setOpenContactIndex(openContactIndex === index ? null : index);
      }
    } else if (type === "directions") {
      window.open(content, "_blank");
    } else if (type === "call") {
      const phoneNumber = content.split('/')[0].trim();
      window.open(`tel:${phoneNumber}`);
    }
  };


  return (
    <section className="about-area-nine pt-70 pb-70 services-bg-two">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-7">
            <ContactAreaMap />
          </div>
          <div className="col-md-5">
            <div className="section-title-two pt-30 tg-heading-subheading animation-style3">
              <span className="sub-title tg-element-title">Our Branches</span>
              <h2 className="title tg-element-title">Discover our offices. Join us today!</h2>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <h5 className="card-title">
                  Bengaluru
                  <span className="badge bg-primary main-branch-label">Main Branch</span>
                </h5>
                <p className="card-text-main"> 1010, 2nd Floor (Above Union Bank) 26th Main, 4th 'T' Block, Jayanagar, Bengaluru - 560041.</p>
                <div className="d-flex ">
                  <button
                    type="button"
                    className="contact-buttons"
                    onClick={() => handleIconClick("email", "roopa@hnaindia.com")}
                    title="Email: roopa@hnaindia.com"
                  >
                    <i className="flaticon-mail"></i>
                  </button>
                  <button
                    type="button"
                    className="contact-buttons mx-3"
                    onClick={() => handleIconClick("phone", "080-26536404 / 080-41210703 / +91 96115 98140", 'main')}
                    title="Phone: 080-26536404 / 080-26536405 / 080-41210703 / +91 96115 98140"
                  >
                    <i className="flaticon-phone-call"></i>
                  </button>
                  <button
                    type="button"
                    className="contact-buttons"
                    onClick={() => handleIconClick("directions", "https://maps.app.goo.gl/BvnwR3zfH67YGyVL6")}
                    title="Directions: https://maps.app.goo.gl/BvnwR3zfH67YGyVL6"
                  >
                    <i className="flaticon-location"></i>
                  </button>
                </div>
                {openMainContact === 'card' && (
  <div className="phone-numbers-list mt-2">
    <h6>Phone:</h6>
    <p onClick={(e) => e.stopPropagation()}>
      {"080-26536404 / 080-26536405 / 080-41210703 / +91 96115 98140".split(" / ").map((number, i, array) => (
        <React.Fragment key={i}>
          {i !== 0 && ", "}
          <span onClick={() => handleIconClick("call", number)}>{number}</span>
        </React.Fragment>
      ))}
    </p>
  </div>
)}

              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 contact-margin">
          {officeLocations.map((location, index) => (
            <div key={index} className="col-md-4 my-3">
              <div className="card ">
                <div className="card-body">
                  <h5 className="card-title">
                    {location.country}
                    {location.mainBranch && <span className="badge bg-primary">Main Branch</span>}
                  </h5>
                  <p className="card-text">{location.address}</p>
                  <div className="d-flex ">
                    {location.email && (
                      <button
                        type="button"
                        className="contact-buttons"
                        onClick={() => handleIconClick("email", location.email)}
                        title={`Email: ${location.email}`}
                      >
                        <i className="flaticon-mail"></i>
                      </button>
                    )}
                    {location.phone && (
                      <div className="phone-numbers">
                        <button
                          type="button"
                          className="contact-buttons mx-3"
                          onClick={() => handleIconClick("phone", location.phone, index)}
                          title={`Phone: ${location.phone}`}
                        >
                          <i className="flaticon-phone-call"></i>
                        </button>

                      </div>
                    )}
                    {location.directions && (
                      <button
                        type="button"
                        className="contact-buttons"
                        onClick={() => handleIconClick("directions", location.directions)}
                        title={`Directions: ${location.directions}`}
                      >
                        <i className="flaticon-location"></i>
                      </button>
                    )}
                  </div>
                  {openContactIndex === index && (
                    <div className="phone-numbers-list mt-2">
                      <h6>Phone:</h6>
                      <p onClick={() => handleIconClick("call", location.phone)}>
                        {location.phone.split(" / ").map((number, i) => (
                          <React.Fragment key={i}>
                            {i !== 0 && ", "}
                            <span onClick={() => handleIconClick("call", number)}>{number}</span>
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  )}

                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
