
import React from "react";

export const ServicesTwoItem = ({ service }) => {
  return (
    <div className="services-item-two">
      <div className="services-thumb-two">
        <img src={service.src} alt={service.alt} />

      </div>
      <div className="services-content-two">
        <div className="icon">
          <i className={service.icon}></i>
        </div>
        <h6 className="title">
          {service.title}
        </h6>
        <p>{service.subtitle}</p>
      </div>
    </div>
  );
};