import React from "react";
import { FEATURE_FOUR_LIST } from "../../mocks/features";

export const FeatureFour = () => {
  return (
    <section className="features-area-four p-5 pointer">
      
      <div className="container">
      <div className="section-title section-title-three mb-60 tg-heading-subheading animation-style1">
                <span className="sub-title tg-element-title">
                  Why Join US
                </span>
                <h2 className="title tg-element-title">
                Empowering Excellence: Advancing Careers at HNA Law Chambers
                </h2>
              </div>
        <div className="row justify-content-center">
  {FEATURE_FOUR_LIST.map((feature) => (
    <div key={feature.id} className="col-lg-4 col-md-6 mb-4">
      <div
          style={{
            padding: "15px",
            background: "#ffffff",
            borderRadius: "8px",
            boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)",
            minHeight: "180px", // Minimum height for each feature item
            display: "grid",
            gridTemplateColumns: "auto 1fr", // One column for icon, one for title and subtitle
            alignItems: "center", // Center content vertically
            columnGap: "10px", // Gap between icon and text
            minheight: "200px", // Set a fixed height for all grid items
          }}
        className="features-item-four equal-height-card"
      >
        <div className="features-icon-four text-center">
          <i className={feature.icon} style={{ fontSize: "30px", color: "#007bff" }}></i>
        </div>
        <div className="features-content-four">
          <h4 className="title mb-3" style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>
            {feature.title}
          </h4>
          <p className="mb-0" style={{ fontSize: "14px", color: "#666" }}>
            {feature.subtitle}
          </p>
        </div>
      </div>
    </div>
  ))}
</div>

      </div>
    </section>
  );
};
