import { useState, useEffect } from 'react';

export const WelcomeModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const lastAgreedTimestamp = localStorage.getItem('lastAgreedTimestamp');
    const now = new Date().getTime();
    const twelveHoursInMillis = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

    // If last agreed timestamp exists and it's within 12 hours, don't show the modal
    if (lastAgreedTimestamp && now - lastAgreedTimestamp < twelveHoursInMillis) {
      setShowModal(false);
    } else {
      setShowModal(true);
      // Disable background scrolling when modal is open
      document.body.classList.add('modal-open');
    }

    return () => {
      // Re-enable background scrolling when modal is closed
      document.body.classList.remove('modal-open');
    };
  }, []);

  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem('lastAgreedTimestamp', new Date().getTime());
  };

  return (
    <>
      <div className={`modal-backdrop fade ${showModal ? 'show' : ''}`} style={{ display: `${showModal ? 'block' : 'none'}` }}></div>
      <div className={`modal ${showModal ? 'show' : 'fade'}`} tabIndex="-1" style={{ display: `${showModal ? 'block' : 'none'}` }}>
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ color: '#333', backgroundColor: '#fff' }}>
            <div className="modal-header ">
              <h5 className="modal-title text-primary">DISCLAIMER</h5>
            </div>
            <div className="modal-body">
            <p className='modal-font' style={{ color: '#333' }}>
                The Bar Council of India does not permit advertisement or solicitation by advocates in any form
                or manner. By accessing this website, <a href="http://www.hnalawchambers.com" className="font-semibold" style={{ color: '#0056b3' }}>www.hnalawchambers.com</a>, you acknowledge and confirm
                that you are seeking information relating to HNA Law Chambers, of your own accord and that
                there has been no form of solicitation, advertisement or inducement by HNA Law Chambers, or
                its members. The content of this website is for informational purposes only and should not be
                interpreted as soliciting or advertisement. No material/information provided on this website
                should be construed as legal advice. HNA Law Chambers shall not be liable for consequences of
                any action taken by relying on the material/information provided on this website. The contents
                of this website are the intellectual property of HNA Law Chambers.
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={closeModal}>I Agree</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

