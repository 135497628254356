import React from "react";
import {
  H2_ABOUT_IMG01,
  H2_ABOUT_IMG02,
  H2_ABOUT_SHAPE02,
  H2_ABOUT_SHAPE03,
} from "../../lib/assets";

export const AboutThree = () => {
  const list = [
    "High Court and Supreme Court Representation",
    "Representation at other forums and Authorities for Advanced Ruling",
    "Strategic advisory on litigation aspects",
    "Tax structuring and optimization for litigation",
    "Assistance in filing Industry Representations before Government Authorities",
  ];

  return (
    <section className="about-area-three">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="about-img-wrap-three">
              <img
                src={H2_ABOUT_IMG01}
                alt=""
                data-aos="fade-down-right"
                data-aos-delay="0"
              />
              <img
                src={H2_ABOUT_IMG02}
                alt=""
                data-aos="fade-left"
                data-aos-delay="400"
              />
              <div
                className="experience-wrap"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h2 className="title">
                  26 <span>Years</span>
                </h2>
                <p>Of Professional Expertise at H N A</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content-three">
              <div className="section-title-two mb-20 tg-heading-subheading animation-style3">
                <span className="sub-title" style={{ color: '#0056b3' }}>Our Legal Solutions</span>
                <h2 className="title tg-element-title">
                Trusted Legal Guidance and Representation
                </h2>
              </div>
              <p className="info-one">
              Explore the depth of legal solutions at H N A Law Chambers. Our services encompass:
              </p>

              <div className="about-list-two">
                <ul className="list-wrap">
                  {list.map((item, idx) => (
                    <li key={idx}>
                      <i className="fas fa-arrow-right"></i> {item}
                    </li>
                  ))}
                </ul>
              </div>
           
             
            </div>
          </div>
        </div>
      </div>
      <div className="about-shape-wrap-two">
        <img src={H2_ABOUT_SHAPE02} alt="" />
        <img src={H2_ABOUT_SHAPE02} alt="" />
        <img
          src={H2_ABOUT_SHAPE03}
          alt=""
          data-aos="fade-left"
          data-aos-delay="500"
        />
      </div>
    </section>
  );
};
