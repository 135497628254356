import React, { useState } from "react";
import { ScrollToTopButton } from "../components/ScrollToTopButton/ScrollToTopButton";
// import { FooterTwo } from "../components/Footers/FooterTwo";
import { HeaderSix } from "../components/Headers/HeaderSix";
import { WelcomeModal } from "../components/WelcomeModal";
import { FooterOne } from "../components/Footers/FooterOne";
import { HeaderOne } from "../components/Headers/HeaderOne";
import { Breadcrumb } from "../components/Breadcrumb/Breadcrumb";

export const Layout = ({ children, header, footer, breadcrumb, title }) => {
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const handleAgree = () => {
    setShowDisclaimer(false);
  };

  return (
    <>
      {/* Scroll-top */}
      <ScrollToTopButton />

      {showDisclaimer && <WelcomeModal onAgree={handleAgree} />}
      <div className={showDisclaimer ? "blur-background" : ""}>
        {/* Header */}
        {header === 1 && <HeaderOne />}
        {!header || header === 6 ? <HeaderSix /> : <></>}

        {/* Breadcrumb */}
        {breadcrumb && <Breadcrumb text={breadcrumb} title={title} />}

        {/* Main content */}
        <main className="fix">{children}</main>

        {/* Footer */}
        {!footer || footer === 1 ? <FooterOne /> : <></>}
        {/* {footer === 2 && <FooterTwo />} */}
      </div>
    </>
  );
};
